<template>
  <div
    class="tt-avatar-group d-flex flex-row justify-start"
    :data-test="dataTest"
    :data-test-label="dataTestLabel"
    :data-test-value="dataTestValue"
  >
    <TTAvatar
      v-for="(i, idx) in itemsVisible"
      :key="idx"
      :src="i.src"
      :text="i.text"
      :icon="i.icon"
      :small="small"
      :x-small="xSmall"
    />
    <div
      v-if="itemsRestQuantity"
      class="tt-avatar-group__rest d-flex justify-center align-center rounded-circle tt-light-mono-8"
    >
      <span class="tt-light-mono-64--text">
        +{{ itemsRestQuantity }}
      </span>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import take from 'lodash/take';
import TTAvatar from '../TTAvatar/TTAvatar.vue';

// TODO: тесты!!!
export default Vue.extend({
  name: 'TTAvatarGroup',
  components: {
    TTAvatar,
  },
  props: {
    dataTest: {
      type: String,
      default: 'tt-avatar-group',
    },
    dataTestLabel: {
      type: String,
      default: '',
    },
    dataTestValue: {
      type: String,
      default: '',
    },
    items: {
      type: Array,
      default: () => [],
    },
    limit: {
      type: Number,
      default: 3,
    },
    small: {
      type: Boolean,
      default: false,
    },
    xSmall: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    itemsVisible() {
      const quantity = this.isOverflow ? this.limit - 1 : this.limit;
      return this.limit > 0 ? take(this.items, quantity) : this.items;
    },
    itemsRestQuantity() {
      return this.isOverflow ? this.items.length - (this.limit - 1) : 0;
    },
    isOverflow() {
      return this.items.length > this.limit;
    },
  },
});
</script>

<style lang="scss" scoped src="./TTAvatarGroup.scss" />
